import React from "react";
import { Link } from "react-router-dom";
// import Editicon from "../../assets/images/home/edit.svg";

const Table = ({ headers, nfts }) => {
  return (
    <center>
      <table className="revise-table-container">
        <thead>
          <tr className="revise-header">
            {headers.map((head, i) => {
              return (
                <th key={i} className="title">
                  {head.header}
                </th>
              );
            })}
            <th className="title">ACTION</th>
          </tr>
        </thead>
        <tbody>
          {nfts.map((row, i) => {
            return (
              <tr className="revise-table-data" key={i}>
                {headers.map((col, id) => {
                  return (
                    <td key={id} className="table-data">
                      {row[col.field]}
                    </td>
                  );
                })}
                <td className="table-data">
                  <Link to={`/revisions/${row.id}`} className="common-btn">
                    View
                  </Link>
                  <a
                    href={
                      row.collection
                        ? `https://${row.collection.collectionURI}.revise.link/${row.tokenId}`
                        : `https://revise.link/${row.id}`
                    }
                    className="common-btn"
                    target="_blank"
                  >
                    Token URL
                  </a>
                  {/* <a href="#"><img src={Editicon} /></a> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </center>
  );
};
export default Table;
