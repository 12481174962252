import React, { useRef } from "react";
import congratulationIcon from "../../../assets/images/live-traits/congratulations-icon.svg";
import { useNavigate } from "react-router-dom";
import { loginAPI } from "../../../services/api";
import "./liveTraitsPopUp.scss";

export const LiveTraitsRetryPopUp = ({ retryPopupModal }) => {
  const completedPopupModal = useRef();
  const hiHello = useRef();

  const handleOnclick = () => {
    retryPopupModal.style.display = "none";
  };

  const handleNext = () => {
    completedPopupModal.current.style.display = "block";
    hiHello.current.style.display = "none";
  };

  return (
    <div className="liveTraitsPopupWrapper">
      <div className="liveTraitsPopup" ref={hiHello}>
        <div className="liveTraitsPopupBox">
          <img
            src={congratulationIcon}
            alt="Congratulations"
            className="liveTraitsPopupBoxImage"
          />
          <h1 className="liveTraitsPopUpText">The code ran successfully!</h1>
          <div className="liveTraitsPopUpButtonWrapper">
            <button
              className="liveTraitsPopUpDashboardButton"
              onClick={handleNext}
            >
              Next
            </button>
            <a href="https://linktr.ee/revise_network" target="_blank">
              <button
                className="liveTraitsPopUpRetryButton"
                onClick={handleOnclick}
              >
                I am Stuck
              </button>
            </a>
          </div>
        </div>
      </div>
      <div ref={completedPopupModal} className="copySuccessPopupModal">
        <div className="copySuccessPopupModalContent">
          <LiveTraitsCompletedPopUp retryPopupModal={retryPopupModal} />
        </div>
      </div>
    </div>
  );
};

export const LiveTraitsCompletedPopUp = ({ retryPopupModal }) => {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("userEmail");

  const handleOnclick = async () => {
    if (userEmail !== null) {
      try {
        const data = await loginAPI({ email: userEmail });
        if (data.status === 200) {
          localStorage.removeItem("onboardToken");
          localStorage.removeItem("userEmail");
          navigate("/success");
        }
      } catch (error) {
        alert("something went wrong");
      }
    }
  };

  const handleRetry = () => {
    retryPopupModal.style.display = "none";
  };

  return (
    <div className="liveTraitsPopupWrapper">
      <div className="liveTraitsPopup">
        <div className="liveTraitsPopupBox">
          <img
            src={congratulationIcon}
            alt="Congratulations"
            className="liveTraitsPopupBoxImage"
          />
          <h1 className="liveTraitsPopUpText">
            Congratulations on making your first livetrait NFT.
          </h1>
          <div className="liveTraitsPopUpButtonWrapper">
            <button
              className="liveTraitsPopUpDashboardButton"
              onClick={handleOnclick}
            >
              Go to dashboard
            </button>
            <button
              className="liveTraitsPopUpRetryButton"
              onClick={handleRetry}
            >
              Retry
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
