import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./generateapimodal.scss";
import GenerateIcon from "../../assets/images/generateapi/generate.svg";
import { generateAPIKey } from "../../services/api";
import { toast } from "react-toastify";

export default function CustomModal({ show, handleClose, token: userProfile }) {
  const [keyGenerated, setKeyGenerated] = useState(false);
  const [generateKeyBtnTxt, setGenerateKeyBtnTxt] =
    useState("Generate API Key");
  const [secret, setSecret] = useState("");
  const [apikey, setApikey] = useState("");

  async function generateKey(e) {
    e.preventDefault();
    setGenerateKeyBtnTxt("Generating please wait...");
    const { token, secret: secretText } = await generateAPIKey({
      token: userProfile,
    });
    setKeyGenerated(true);
    setGenerateKeyBtnTxt("Generated");
    setApikey(token);
    setSecret(secretText);
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setKeyGenerated(false);
        setApikey("");
        setSecret("");
        handleClose();
      }}
      animation={false}
      backdrop="static"
      size="md"
      className="modalBg"
    >
      <Modal.Header>
        <Modal.Title>
          <h4 className="d-inline">Generate API Key</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="generateApiModal">
          {keyGenerated ? (
            <center>
              <div className="">
                <h5>API Secret</h5>
                <input
                  value={secret}
                  disabled
                  style={{
                    textAlign: "center",
                    borderRadius: "50px",
                    color: "#9A9AB0",
                  }}
                  placeholder="Automatically generated"
                />
                <h5 className="mt-3">API Key</h5>
                <textarea
                  disabled
                  placeholder="Automatically generated"
                  style={{
                    width: "100%",
                    height: "120px",
                    textAlign: "center",
                    padding: "10px",
                  }}
                  defaultValue={apikey}
                ></textarea>
                <div
                  style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <Button
                    size="md"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        JSON.stringify({
                          secret: secret,
                          apiKey: apikey,
                        })
                      );
                      toast.success("Copied Successfully");
                    }}
                  >
                    Copy Key & Secret
                  </Button>
                  <Button
                    size="md"
                    onClick={() => {
                      navigator.clipboard.writeText(apikey);
                      toast.success("Copied Successfully");
                    }}
                  >
                    Copy Key
                  </Button>
                </div>
              </div>
            </center>
          ) : (
            <center>
              <div id="loading--inner" className="loading--inner">
                <div
                  className="loading--box upper loading--box-animate"
                  style={{ left: 20, bottom: 0, animationDelay: ".7s" }}
                />
                <div
                  className="loading--box loading--box-animate"
                  style={{ left: 32, bottom: 0, animationDelay: ".6s" }}
                />
                <div
                  className="loading--box dbl-width loading--box-animate"
                  style={{ left: 44, bottom: 0, animationDelay: ".5s" }}
                />
                <div
                  className="loading--box sink loading--box-animate"
                  style={{ left: 50, bottom: 11, animationDelay: ".5s" }}
                />
                <div
                  className="loading--box hang loading--box-animate"
                  style={{ left: 44, top: 0, animationDelay: ".4s" }}
                />
                <div
                  className="loading--box hang loading--box-animate"
                  style={{ left: 56, top: 0, animationDelay: ".3s" }}
                />
                <div
                  className="loading--box loading--box-animate"
                  style={{ left: 68, bottom: 0, animationDelay: ".1s" }}
                />
                <div
                  className="loading--box upper loading--box-animate"
                  style={{ left: 68, top: 0, animationDelay: ".1s" }}
                />
                <div
                  className="loading--box dbl-height loading--box-animate"
                  style={{ left: 80, bottom: 0 }}
                />
                <div className="loading--text">{generateKeyBtnTxt}</div>
              </div>
            </center>
          )}

          <p className="mt-4">
            Please copy and keep the API Key safe. We won't be storing it. We'll
            just keep the secret so you can identify your API Key
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="lg"
          //   variant="dark"
          disabled={apikey}
          onClick={generateKey}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          {!apikey && <img alt="" src={GenerateIcon} />}
          {generateKeyBtnTxt}
        </Button>
        <Button
          size="lg"
          variant="dark"
          onClick={() => {
            setKeyGenerated(false);
            setApikey("");
            setSecret("");
            handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
