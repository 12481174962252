import axios from "axios";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import Back from '../../../assets/images/login/back.svg';
import Logo from '../../../assets/images/login/logo.svg';

import "./login.scss";

const LiveTraitsLogin = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [emailId, setEmailId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("onboardToken")) {
      navigate("/create-nft");
    }
    if (localStorage.getItem("userProfile")) {
      navigate("/");
    }
  }, []);

  //handle input change
  const handleChange = (event) => {
    setEmailId(event.target.value);
  };

  let mailFormat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");
    if (emailId === "") {
      setLoading(false);
      setErrorMessage("Enter your Email Id");
    } else if (emailId.match(mailFormat)) {
      try {
        const { data } = await axios.post(`${BASE_URL}/auth/v3/login`, {
          email: emailId,
        });
        if (!data) {
          throw {
            message: "Something Went Wrong",
          };
        }
        if (data?.tempToken) {
          setLoading(false);
          localStorage.setItem("onboardToken", data.tempToken);
          localStorage.setItem("userEmail", emailId);
          navigate("/create-nft");
        } else {
          setLoading(false);
          localStorage.removeItem("onboardToken");
          localStorage.removeItem("userEmail");
          navigate("/success");
        }
      } catch (error) {
        setLoading(false);

        alert("Something went wrong");
      }
    } else {
      setLoading(false);

      setErrorMessage("Enter a valid Email Id");
    }
  };

  return (
    <>
    {/* <UserGuide /> */}
      <section className="login">
        <div className="loginHead">
          <Link to=""><img src={Back} alt="back" />Back</Link>
        </div>
        <div className="container">
          <div className="loginData">
            <div className="loginContainer">
              <div className="loginContent">
                <div>
                  <img src={Logo} alt="revise" />
                </div>
                <h1>You’re just one step away from getting a LiveTrait</h1>
              </div>
              <div className="loginForm">
                  <h2>Enter Email ID</h2>
                  <form>
                    <div className="inputField">
                      <input type="text" placeholder="Enter Email" value={emailId} onChange={handleChange}/>
                      <p className="error-message">{errorMessage}</p>
                    </div>
                    <div className="formSubmit">
                      <Link to="">Get help</Link>
                      <button type="submit"  
                        onClick={(event) => {
                        if (loading) {
                          event.preventDefault();
                          return;
                        } else {
                          handleFormSubmit(event);
                        }
                      }}
                    >Next <img src={Back} alt="next" /></button>
                    </div>
                  </form>
                  <p>By clicking next, you agree to our <Link to="">terms and conditions</Link> and <Link to="">privacy policy</Link></p>
              </div>
            </div>
          </div>
        </div>
        <div className="copyRights">
            <p>Copyright © 2022 Revise Innovations.</p>
            <p>Legal</p>
        </div>
      </section>

    </>
  );
};

export default LiveTraitsLogin;
