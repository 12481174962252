import React from "react";
import "./style.scss";
import heart from "../../assets/images/heart.svg";
import reviseWhite from "../../assets/images/reviseWhite.svg";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footerWrapper">
          <div className="footerLogo">
            <img src={reviseWhite} alt="revise" />
          </div>
          <p>
            With <img src={heart} alt="heart" height={"70%"} /> from revise
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
