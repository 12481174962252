import React from "react";
import "./register.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { registerAPI } from "../../services/api";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Success from "../success";
import { toast } from "react-toastify";

const Register = () => {
  const [emailId, setEmailId] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem("userProfile")) {
      window.location.href = "/";
    }
  });

  const onRegister = async (e) => {
    e.preventDefault();
    try {
      await registerAPI({ email: emailId });
      setRegistrationSuccess(true);
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Something Went Wrong..! Please Try Again"
      );
    }
  };

  return (
    <>
      <Header skipLogout />
      {!registrationSuccess ? (
        <section className="registerSection">
          <div className="container">
            <div className="registerContainer">
              <div className="registerButtons">
                <Link to="/signin">Sign In</Link>
                <a href="/register" className="active">
                  Register
                </a>
              </div>
              <div className="registerForm">
                <h1>Hello,</h1>
                <form>
                  <label>Email</label>
                  <input
                    placeholder="Enter Email"
                    type="email"
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    required
                  />
                  <button
                    type="submit"
                    className="common-btn"
                    onClick={(e) => onRegister(e)}
                  >
                    Register
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div>
          <Success />
        </div>
      )}
      <Footer />
    </>
  );
};
export default Register;
