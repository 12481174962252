import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./revisions.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
// import whale from "../../assets/images/revisions/whale.png";
import { fetchNFTandRevisionsAPI } from "../../services/api";
import moment from "moment";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import CustomModal from "./modal";
let attrCount = 2;

const Revisions = () => {
  // const [userProfile, setUserProfile] = useState(null);
  const [revisions, setRevisions] = useState([]);
  const [nft, setNFT] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nftRevision, setNftRevision] = useState("");
  const params = useParams();

  useEffect(() => {
    fetchUserProfile().then(fetchRevisions);
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setShow(!show);
  };

  async function fetchUserProfile() {
    const userProfile = window.localStorage.getItem("userProfile");
    // if(userProfile === null || userProfile === undefined){
    //   window.location.href = '/';
    // }
    // setUserProfile(userProfile);
    return userProfile;
  }

  async function fetchRevisions(userProfile) {
    try {
      setLoading(true);
      // const nftData = await fetchNFTAPI({
      //   token: userProfile,
      //   nftId: params.id,
      // });
      const nftData = await fetchNFTandRevisionsAPI({
        nftId: params.id,
      });
      const revisions = nftData.revisions
        ? nftData.revisions.map((r, i) => {
            r.sno = nftData.revisions.length - i;
            r.createdAtTime = moment(r.createdAt).format("h:mm:ss A");
            r.createdAt = moment(r.createdAt).format("Do MMMM YYYY");
            return r;
          })
        : [];
      setRevisions(revisions);
      setNFT(applyMoment(applyIPFSGateway(nftData)));
    } catch (error) {
      alert("could not fetch data");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function applyMoment(nft) {
    nft.createdAt = moment(nft.createdAt).format("Do MMMM YYYY");
    nft.updatedAt = moment(nft.updatedAt).fromNow();
    return nft;
  }
  function applyIPFSGateway(nft) {
    if (!nft.image) return nft;
    if (nft.image.toLowerCase().indexOf("ipfs://") !== 0) return nft;

    nft.image = `https://ludolabs.mypinata.cloud/ipfs/` + nft.image.slice(7);
    return nft;
  }

  function Items({ currentItems }) {
    return (
      <div className="logDetails">
        {currentItems &&
          currentItems.map((item, i) => {
            return (
              <div key={i} className="logText">
                <div className="logTiming">
                  <span className="logDate">{item.createdAt} • </span>
                  <span className="logTime">{item.createdAtTime}</span>
                </div>
                <div className="logContent">
                  <div className="logData">
                    <label>Revision ID: </label>
                    <p>{item.id}</p>
                  </div>
                  <div className="logData">
                    <label>Revision:</label>
                    <p>{item.sno}</p>
                  </div>
                  <div className="logData">
                    <label>Created At:</label>
                    <p>{item.createdAt}</p>
                  </div>
                  <div>
                    <Button
                      className="ms-5 float-end"
                      onClick={() => {
                        setNftRevision(item);
                        setShow(true);
                      }}
                    >
                      See Full Revision
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = revisions.slice(itemOffset, endOffset);

    const pageCount = Math.ceil(revisions.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % revisions.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />

        <div className="pt-4 float-end">
          <ReactPaginate
            renderOnZeroPageCount={null}
            previousLabel="<<"
            nextLabel=">>"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </>
    );
  }

  function Success() {
    return (
      <>
        <Header />
        <section className="revisionsSection">
          <div className="container">
            <div className="revisionsContainer">
              <div className="revisionsCard">
                <div className="collectionContent">
                  <div className="imageWrapper">
                    <img alt="NFT" src={nft.image} />
                  </div>

                  <h3>{nft.name}</h3>
                  <h4>
                    Collection -{" "}
                    {nft.collection ? nft.collection.collectionName : "N.A."}
                  </h4>
                  <p
                    style={{
                      overflowWrap: "anywhere",
                      textAlign: "justify",
                      textAlignLast: "center",
                      fontSize: "14px",
                    }}
                  >
                    {nft.description}
                  </p>
                  <a
                    href={
                      nft.collection
                        ? `https://${nft.collection.collectionURI}.revise.link/${nft.tokenId}`
                        : `https://revise.link/${nft.id}`
                    }
                    className="common-btn-url"
                    target="_blank"
                  >
                    Token URL
                  </a>
                </div>
                <div className="collectionModified">
                  <div>
                    <h4>Date Added</h4>
                    {/* Do MMMM YYYY */}
                    <p>{nft.createdAt}</p>
                  </div>
                  <div>
                    <h4>Revisions</h4>
                    <p>{nft._count ? nft._count.revisions : "N.A."}</p>
                  </div>
                  <div>
                    <h4>Last Updated</h4>
                    <p>{nft.updatedAt}</p>
                    {/* <p>1 Month ago</p> */}
                  </div>
                </div>
                <div className="collectionAddress">
                  <h4>CONTRACT ADDRESS:</h4>
                  <p>Not added yet</p>
                </div>
                <div className="collectionModifiedMeta">
                  {nft && Array.isArray(nft.metaData) ? (
                    nft?.metaData?.map((attr, i) => {
                      if (attrCount == 3) {
                        attrCount = 1;
                      } else {
                        attrCount += 1;
                      }
                      if (i == 0) {
                        attrCount = 2;
                      }
                      return (
                        <div
                          key={i}
                          className={`${
                            attrCount !== 3
                              ? attrCount === 1
                                ? "left-border"
                                : "right-border"
                              : nft?.metaData.length === i + 1
                              ? "left-border"
                              : "half-border"
                          }`}
                        >
                          <h4 className="text-capitalize">
                            {Object.keys(attr)[0]}
                            {/* {attrCount} */}
                          </h4>
                          <p> {attr[Object.keys(attr)[0]] || "N/A"}</p>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <p>Attributes not found or invalid</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="revisionsCard">
                <div className="logHeading">
                  <h1>Revisions</h1>
                </div>
                <PaginatedItems className itemsPerPage={5} />
              </div>
            </div>
          </div>

          <CustomModal
            nftRevision={nftRevision}
            show={show}
            handleClose={handleClose}
          />
        </section>
        <Footer />
      </>
    );
  }
  let PageProps = {
    true: (
      <div className="loader d-flex justify-content-center align-items-center">
        <Spinner variant="primary" animation="border" />
      </div>
    ),
    false: <Success />,
  };

  return <>{PageProps[loading]}</>;
};
export default Revisions;
