const returnRandomNumber = () => Math.floor(Math.random() * 40 + 60);
const returnFourDigitRandomNumber = () =>
  Math.floor(1000 + Math.random() * 9000);

const BASE_URL = process.env.REACT_APP_API_URL;

export const NFTs = [
  {
    id: 1,
    name: "Cyborg86 #" + returnFourDigitRandomNumber(),
    nftUniqueId: returnFourDigitRandomNumber(),
    image: `${BASE_URL}/nft-image/cyborg.png`,
    description:
      " Cyborg86 is an NFT in the collection of 10,000 unique, 3D, cyberpunk-inspired cyborgs. This is built from 4 traits that can be updated.",
    properties: [
      { Stamina: returnRandomNumber() },
      { Energy: returnRandomNumber() },
      { Health: returnRandomNumber() },
    ],
  },
  {
    id: 2,
    name: "Moody Ape 888 #" + returnFourDigitRandomNumber(),
    nftUniqueId: returnFourDigitRandomNumber(),
    image: `${BASE_URL}/nft-image/weather.png`,
    description:
      "Moody Ape 888 is an NFT in the collection of 1000 unique apes. This ape is exploring the web 3 universe one step at a time and earning points as it goes along, but consuming stamina.",
    properties: [
      { Health: returnRandomNumber() },
      { Stamina: returnRandomNumber() },
      { Points: returnRandomNumber() },
      { Swag: returnRandomNumber() },
    ],
  },
  {
    id: 3,
    name: "Kylian Mbappé #" + returnFourDigitRandomNumber(),
    nftUniqueId: returnFourDigitRandomNumber(),
    image: `${BASE_URL}/nft-image/sport.png`,
    description:
      "Kylian Mbappé Lottin is a French professional footballer who plays as a forward for Ligue 1 club Paris Saint-Germain and the France national team. Regarded as one of the best players in the world, he is renowned for his dribbling abilities, exceptional speed, and finishing.",
    properties: [
      { Stamina: returnRandomNumber() },
      { Defense: returnRandomNumber() },
      { Health: returnRandomNumber() },
      { Skills: returnRandomNumber() },
    ],
  },
];
