import React from "react";
import "./home.scss";
import Table from "../../components/table";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Docs from "../../assets/images/home/docs.svg";
import { fetchNFTsAPIV2 } from "../../services/api";
import { useState, useEffect } from "react";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const Dashboard = () => {
  const headers = [
    { field: "sno", header: "S NO" },
    { field: "name", header: "NAME" },
    { field: "collectionName", header: "COLLECTION" },
    { field: "updatedAt", header: "LAST UPDATED" },
    { field: "revisionsCount", header: "REVISIONS" },
    { field: "createdAt", header: "DATE ADDED" },
  ];

  // const [userProfile, setUserProfile] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [totalPage, setTotalPage] = useState(0);
  const [totalNft, setTotalNft] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");

  const searchValue = useDebounce(searchFilter, 1000);

  useEffect(() => {
    fetchUserProfile()
      .then((userProfile) => {
        return userProfile;
      })
      .then((userProfile) => {
        if (!userProfile) return;
        fetchNFTs({ token: userProfile });
      });
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (
      (currentPage === 0 && searchValue !== "") ||
      (currentPage === 0 && searchValue === "" && nfts.length > 0)
    ) {
      fetchUserProfile()
        .then((userProfile) => {
          return userProfile;
        })
        .then((userProfile) => {
          if (!userProfile) return;
          fetchNFTs({ token: userProfile });
        });
    } else setCurrentPage(0);
  }, [searchValue]);

  async function fetchUserProfile() {
    try {
      setLoading(true);
      const userProfile = window.localStorage.getItem("userProfile");
      if (userProfile === null || userProfile === undefined) {
        window.location.href = "/signin";
      }
      // setUserProfile(userProfile);
      return userProfile;
    } catch (error) {
      alert(error);
    }
  }

  async function fetchNFTs({ token, collectionId }) {
    try {
      const data = await fetchNFTsAPIV2(
        { token, collectionId },
        { perPage, currentPage, keyword: searchFilter }
      );
      setNfts(
        data.nfts.map((nft, i) => {
          nft.createdAt = moment(nft.createdAt).format("DD MMM YYYY");
          nft.updatedAt = moment(nft.updatedAt).format("DD MMM YYYY");
          nft.collectionName = nft.collection
            ? nft.collection.collectionName
            : "N.A.";
          nft.revisionsCount = nft._count ? nft._count.revisions : "N.A.";
          nft.sno = perPage * currentPage + (i + 1);
          return nft;
        })
      );

      setTotalPage(data.pagination.totalPages || 0);
      setTotalNft(data.pagination.totalNfts);
      // setCurrentPage(data.pagination.currPage);
      return nfts;
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  return loading ? (
    <div className="loader d-flex justify-content-center align-items-center">
      <Spinner variant="primary" animation="border" />
    </div>
  ) : (
    <div className="aWrapper">
      <Header />
      <section className="dashboard" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="nftsCollection">
            <div className="nftsHeading">
              {/* eslint-disable-next-line */}
              <a className="nftsLabel active">
                {totalNft || 0} {totalNft ? "NFT" : "NFTs"}
              </a>
              {/* <a className="collectionsLabel">2 COLLECTIONS</a>                 */}
            </div>
            <div
              className="nftsButtons"
              title="Search by Name & Collection Name"
            >
              {/* <label for="fname">First Name</label> */}
              <input
                type="text"
                key={"Search-Filter"}
                defaultValue={searchFilter}
                onChange={(e) => {
                  setSearchFilter(e.target.value);
                }}
                id="fname"
                name="search"
                placeholder="Search"
                autoComplete="off"
              />
            </div>
            <div className="nftsButtons">
              {/* <a href="#" className="common-btn">+ ADD NFT</a> */}
              <a href="https://docs.revise.network" className="common-btn">
                <img alt="documentation icon" src={Docs} /> EXPLORE DOCS
              </a>
            </div>
          </div>
        </div>
        <div className="tableContainer active">
          <Table headers={headers} nfts={nfts} />
          <center>
            <h3 style={{ color: "white", margin: "40px 0" }}>
              {nfts.length === 0 && "No NFTs Found"}
            </h3>
          </center>
        </div>

        <div className="revise-align-center">
          <ReactPaginate
            previousLabel="<<"
            breakLabel="..."
            renderOnZeroPageCount={null}
            nextLabel=">>"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            initialPage={currentPage}
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={totalPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(e) => {
              setCurrentPage(parseInt(e.selected));
            }}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
        {/* <div className="tableContainer">                  
                <Table headers={headers} tableData={tableData} />
              </div> */}
        <div className="container">
          <hr />
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Dashboard;
