import React from "react";
import AppRoutes from "./routes";
import "./styles/common.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { createRoot } from "react-dom/client";

createRoot(document.getElementById("root")).render(
  // <React>
  <AppRoutes />
  // </React>
);
