import React from "react";
import Load from "../../assets/images/live-traits/logo.png";

import "./loader.scss";

const Loader = ({ small }) => {
  return (
    <section
      className={`${small === "small" ? "loaderCustom small" : "loaderCustom"}`}
    >
      <div className="loaderImg">
        <img src={Load} alt="loader" />
      </div>
    </section>
  );
};

export default Loader;
