import React from "react";
import "./success.scss";
// import Header from "../../components/headerlogin";
import Footer from "../../components/footer";
import successImg from "../../assets/images/success/success.png";
import {Link} from 'react-router-dom'

const Success = () => {
  return (
    <>
        <section className="registerSection successSection">
          <div className="container">
            <div className="registerContainer">
              <div className="registerForm">
                <div className="imageWrapper">
                  <img alt="success icon" src={successImg} />
                </div>
                <h1>A login link has been sent to you on your email. </h1>
                <Link to="/"><button type="submit" className="common-btn">Go back</button></Link>
              </div>
            </div>
          </div>
        </section>
      <Footer /> 
    </>
  );
};
export default Success;
