import React, { useEffect, useState, useRef } from "react";
import Editor from "@monaco-editor/react";
import copyIcon from "../../../assets/images/live-traits/copy-icon.svg";
import Header from "../../../components/header";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import Footer from "../../../components/footer";
import {
  LiveTraitsCompletedPopUp,
  LiveTraitsRetryPopUp,
} from "../live-trait-pop-ip/LiveTraitsPopUp";
import { NFTs } from "../../../services/demoNFTs";
import Loader from "../../../components/loader/index";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./liveTraitsTerminalPage.scss";

const LiveTraitsTerminalPage = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const params = useParams();
  const [copied, setCopied] = useState(false);
  const [loader, setLoader] = useState(false);
  const [nft, setNft] = useState({});
  const [secretKey, setSecretKey] = useState("");
  const retryPopupModal = useRef();
  const [selectedMetaData, setSelectedMetaData] = useState([]);
  const [metaData, setMetaData] = useState([]);

  async function popupRetryWindow() {
    setCopied(true);
    retryPopupModal.current.style.display = "block";
  }

  const notify = () =>
    toast("API KEY Copied", {
      className: "toast-message",
      autoClose: 2500,
    });

  const SuccessNotify = () =>
    toast.success("Code copied!", {
      className: "toast-message",
      autoClose: 2500,
    });

  const [codeSnippet, setCodeSnippet] = useState(
    `// Your code will appear here, Please Wait...`
  );

  useEffect(() => {
    if (params?.id) {
      if (Number(params.id) > 3 || Number(params.id) <= 0) {
        navigate("/terminal-code/1");
      }
      let data = NFTs.filter((nft) => Number(nft.id) === Number(params.id));
      setMetaData((prev) => {
        return data[0]?.properties || [];
      });
      data = {
        ...data[0],
        properties: data[0]?.properties.map((val) => {
          return {
            [Object.keys(val)[0]]:
              (Number(Object.values(val)[0]) || 0) + 5 > 100
                ? (Number(Object.values(val)[0]) || 0) - 5
                : (Number(Object.values(val)[0]) || 0) + 5,
          };
        }),
      };
      setNft(data);
      setSelectedMetaData(data.properties.map((val) => Object.keys(val)[0]));
    }
  }, [params.id]);

  useEffect(() => {
    let dataToSend = { ...nft };
    let updateProperties = [];
    dataToSend?.properties?.forEach((val) => {
      if (selectedMetaData.includes(Object.keys(val)[0])) {
        updateProperties.push(val);
      }
    });
    dataToSend.properties = updateProperties;
    getTerminalCode(dataToSend);
  }, [selectedMetaData]);

  const getTerminalCode = async (propsData) => {
    if (localStorage.getItem("onboardToken") === null) {
      navigate("/signin");
    }
    try {
      if (!propsData.id) {
        return;
      }
      setLoader(false);

      const { data } = await axios.post(
        `${BASE_URL}/v3/codeSnippet`,
        {
          data: { ...propsData, metaData: metaData || [] },
          token: secretKey || null,
          nftId: nft?.dbNftId || null,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("onboardToken")}`,
          },
        }
      );
      setLoader(false);

      if (data) {
        setCodeSnippet(data.codeSnippet);
        setSecretKey(data.authToken);
        setNft((prev) => {
          return { ...prev, dbNftId: data.nftId };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <Header skipLogout />
          <div className="liveTraitsTerminalPageWrapper">
            <div className="liveTraitsTerminalLeftSection">
              <p className="liveTraitsTerminalLeftSectionHeader ">
                Select the traits you want to make live (you can update your
                NFT)
              </p>
              <div className="nftDetailsWrapper ">
                <div className="nftDetails ">
                  <div className="nftImageWrapper">
                    <img src={nft?.image} alt="NFT" className="nft-image" />
                    <img
                      src={nft?.image}
                      alt="NFT"
                      className="nft-image-background"
                    />
                  </div>
                  {nft &&
                    nft?.properties?.map((val, key) => {
                      return (
                        <div className="leftBLockNFTDetailsWrapper" key={key}>
                          <div className="checkboxWrapper">
                            <input
                              type="checkbox"
                              name={Object.keys(val)[0]}
                              id={Object.keys(val)[0] + "-" + key}
                              className="checkboxInput"
                              checked={selectedMetaData.includes(
                                Object.keys(val)[0]
                              )}
                              onChange={(e) => {
                                if (e.target.name) {
                                  if (
                                    selectedMetaData.length === 1 &&
                                    selectedMetaData.includes(e.target.name)
                                  ) {
                                    return;
                                  }
                                  if (
                                    selectedMetaData.includes(e.target.name)
                                  ) {
                                    let newValue = selectedMetaData.filter(
                                      (val) => val !== e.target.name
                                    );
                                    setSelectedMetaData(newValue);
                                  } else {
                                    setSelectedMetaData([
                                      ...selectedMetaData,
                                      e.target.name,
                                    ]);
                                  }
                                }
                              }}
                            />
                            <span className="checkMark"></span>
                            <div className="rightBLockNFTDetailsTitle">
                              {Object.keys(val)[0]}
                            </div>
                          </div>
                          <div className="rightBLockNFTDetailsData">
                            {Object.values(val)[0]}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="liveTraitsTerminalRightSection">
              <div className="liveTraitsTerminalRightTopSection">
                <div className="liveTraitsTerminalRightTopSectionTitle">
                  API Secret Key
                </div>
                <CopyToClipboard text={secretKey} onCopy={notify}>
                  <div className="liveTraitsTerminalRightTopSectionData">
                    {secretKey?.slice(0, 14)}.....{secretKey?.slice(-5)}{" "}
                    <img src={copyIcon} alt="copy" />
                  </div>
                </CopyToClipboard>
              </div>
              <div
                className="
            liveTraitsTerminalRightBottomSection"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h1 className="liveTraitsTerminalRightBottomSectionTitle">
                    Run this code in your IDE
                  </h1>
                  <CopyToClipboard text={codeSnippet} onCopy={SuccessNotify}>
                    <div
                      className="liveTraitsTerminalEditorCodeCopyButtonSection"
                      onClick={popupRetryWindow}
                    >
                      <div>Copy</div>
                    </div>
                  </CopyToClipboard>
                </div>
                <div className="liveTraitsTerminalRightBottomSectionEditor">
                  <Editor
                    theme="vs-dark"
                    defaultLanguage="javascript"
                    className="overflow-hidden"
                    defaultValue={codeSnippet}
                    value={codeSnippet}
                    height="35vh"
                    options={{
                      readOnly: true,
                      fontSize: "16px",
                      roundedSelection: false,
                      scrollBeyondLastLine: false,
                      minimap: { enabled: false },
                    }}
                  />
                </div>
                <p className="liveTraitsTerminalRightBottomSectionSupport">
                  Paste this code into your IDE to make the traits LIVE. For
                  more help you can send us a message{" "}
                  <a
                    href="mailto:anil@revise.network"
                    className="support-link"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div ref={retryPopupModal} className="copySuccessPopupModal">
            <div className="copySuccessPopupModalContent">
              {copied ? (
                <LiveTraitsRetryPopUp
                  retryPopupModal={retryPopupModal.current}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div id="#footer">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default LiveTraitsTerminalPage;
