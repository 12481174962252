import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropsTypes from "prop-types";
import "./revisions.scss";

export default function CustomModal({ show, handleClose, nftRevision }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      backdrop="static"
      size="lg"
      className="modalBg"
    >
      <Modal.Header>
        <Modal.Title>
          <h4 className="d-inline">Revision Details </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row modalFilter">
          <div className="collectionImg col-lg-6 col-md-12">
            <div className="modalImg">
              <img
                src={nftRevision.image}
                alt="image"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12 mb-3 modalRow">
            <div className="mt-2 logText">
              <label className="logKeys">Revision Id :</label>
              <span className="logValues"> {nftRevision.id}</span>
            </div>
            <div className="mt-2 logText">
              <label className="logKeys">Name :</label>
              <span className="logValues">{nftRevision.name}</span>
            </div>
            <div className="mt-2 logText">
              <label className="logKeys">Description :</label>
              <span className="logValues">{nftRevision.description}</span>
            </div>
            <div className="mt-2 logText">
              <label className="logKeys">Media Type :</label>
              <span className="logValues">{nftRevision.mediaType}</span>
            </div>
            <div className="mt-2 logText">
              <label className="logKeys">Created At :</label>
              <span className="logValues">{nftRevision.createdAt}</span>
            </div>
            <div className="mt-2 logText">
              <label className="logKeys">Attributes:</label>
              <div
                style={{
                  display: "flex",
                  // justifyItems: "stretch",
                  // justifyContent:"space-evenly",
                  flexWrap: "wrap",
                }}
              >
                {Array.isArray(nftRevision.metaData)
                  ? nftRevision?.metaData?.map(
                      (attr, i) =>
                        Object.keys(attr)[0] && (
                          <div
                            key={i}
                            className="mt-2 logText"
                            style={{
                              border: "1px solid white",
                              textAlign: "center",
                              padding: "5px 20px",
                            }}
                          >
                            <label
                              className="text-capitalize logKeys"
                              style={{ color: "white !important" }}
                            >
                              {Object.keys(attr)[0] &&
                                `${Object.keys(attr)[0]}`}
                            </label>
                            <p className="logValues">
                              {attr[Object.keys(attr)[0]] || "N/A"}
                            </p>
                          </div>
                        )
                    )
                  : "Attributes not found or invalid"}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="lg" variant="dark" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CustomModal.propsTypes = {
  show: PropsTypes.func,
  handleClose: PropsTypes.func,
  nftRevision: PropsTypes.object,
};
