import React from "react";
import { useState } from "react";

import NftFLow from "../../assets/images/login/chooseNft.png";
import Select from "../../assets/images/login/selectTrait.png";
import Code from "../../assets/images/login/code.png";
import Arrow from "../../assets/images/login/right.svg";

import "./style.scss";

const UserGuide = ({setUserGuideModal}) => {
  const [guide, setGuide] = useState(0);

  const handleGuide = (e) => {
    setGuide(e);
  };

  return (
    <section className="guide">
      <div className="container">
        {guide === 0 && (
          <div className="guideBody">
            <div className="guideContainter">
              <div className="guideImg">
                <img src={NftFLow} alt="choose nft" />
              </div>
              <div className="guideContent">
                <div>
                  <label>Using Revise NFT Traits</label>
                  <h2>Choose an NFT flow</h2>
                  <p>We have made a few NFT flows based on major use cases.</p>
                  <p>
                    Select any one flow which is closest to your use case and
                    click on create NFT
                  </p>
                </div>
                <div className="guideNext">
                  <a onClick={() => handleGuide(1)}>
                    Next <img src={Arrow} alt="next" />
                  </a>
                  <div className="dots">
                    <span className="active"></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {guide === 1 && (
          <div className="guideBody">
            <div className="guideContainter">
              <div className="guideImg">
                <img src={Select} alt="choose nft" />
              </div>
              <div className="guideContent">
                <div>
                  <label>Using Revise NFT Traits</label>
                  <h2>Select a trait you want to make Live</h2>
                  <p>Select any property you want to make Live. </p>
                </div>
                <div className="guideNext">
                  <a onClick={() => handleGuide(2)}>
                    Next <img src={Arrow} alt="next" />
                  </a>
                  <div className="dots">
                    <span></span>
                    <span className="active"></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {guide === 2 && (
          <div className="guideBody">
            <div className="guideContainter">
              <div className="guideImg">
                <img src={Code} alt="choose nft" />
              </div>
              <div className="guideContent">
                <div>
                  <label>Using Revise NFT Traits</label>
                  <h2>Get the code & paste to your IDE</h2>
                  <p>
                    We give you the code which you can paste in your IDE and
                    make your trait Live. It’s as easy as 1,2,3
                  </p>
                </div>
                <div className="guideNext">
                  <a onClick={() => setUserGuideModal(false)}>
                    Get Started <img src={Arrow} alt="next" />
                  </a>
                  <div className="dots">
                    <span></span>
                    <span></span>
                    <span className="active"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default UserGuide;
