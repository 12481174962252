import React, { useEffect, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import LiveTraitsNFTFlow from "./LiveTraitsNFTFlow";
import LiveTraitsNFTDetails from "./live-traits-nft-details/LiveTraitsNFTDetails";
import Loader from "../../../components/loader";
import { useNavigate } from "react-router-dom";
import "./liveTraitsCreateNFT.scss";
import UserGuide from "../../../components/modal/UserGuide";

const LiveTraitCreateNFT = () => {
  const [nftId, setNFTId] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userGuideModal, setUserGuideModal] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getNFTDetails();
  }, [localStorage.getItem("token")]);

  const getNFTDetails = async () => {
    setLoading(true);
    if (localStorage.getItem("onboardToken")) {
      setLoading(false);
    } else {
      setLoading(false);
      navigate("/signin");
    }
  };

  return (
    <>
      {
        userGuideModal ?
        <UserGuide setUserGuideModal={setUserGuideModal}/>
        : 
        loading ? (
          <Loader />
        ) : (
          <div className="liveTraitsCreateNFTWrapper">
            <Header skipLogout />
            <div className="liveTraitsNFTFlow">
              <div>
                <LiveTraitsNFTFlow setNFTId={setNFTId} nftId={nftId} />
              </div>
              <div>
                <LiveTraitsNFTDetails nftId={nftId} />
              </div>
            </div>
          </div>
        )}
        <div id="#footer">
          <Footer />
        </div>

    </>
  );
};

export default LiveTraitCreateNFT;
