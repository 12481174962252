import React, { useEffect, useState } from "react";
import "./success.scss";
import Footer from "../../components/footer";
import { validateAPI } from "../../services/api";
import { useParams } from "react-router-dom";

const Validate = () => {
  const [validationMessage, setValidationMessage] =
    useState("Loging you in...");
  const params = useParams();

  useEffect(() => {
    runValidation();
    // eslint-disable-next-line
  }, []);

  async function runValidation() {
    try {
      const data = await validateAPI({ token: params.token });
      console.log({ data });
      localStorage.setItem("userProfile", data.token.token);
      localStorage.setItem("userEmail", data.user.email);
      window.location.href = "/";
    } catch (error) {
      setValidationMessage("Invalid link..");
    }
  }
  return (
    <>
      <section className="registerSection successSection">
        <div className="container">
          <div className="registerContainer">
            <div className="registerForm">
              <h1>{validationMessage}</h1>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Validate;
