import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "../../containers/generateapi/generateapi.scss";

export default function CustomModal({ show, handleClose, handleDelete, id }) {
  const [btnShow, setBtnShow] = useState(true);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      backdrop="static"
      size="md"
      className="modalBg"
    >
      <Modal.Header>
        <Modal.Title>
          <h4 className="d-inline">Deactivate API Key</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="deactivate-modal">
          <h4>
            Deactivating Key :- <span style={{ color: "#544BFA" }}>{id}</span>
          </h4>
          <p className="mt-4">
            Warning :- Once you deactivate the key, The process cant be revert
            and the existing application running with this API key wont work.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {btnShow && (
          <Button
            size="lg"
            onClick={() => {
              setBtnShow(false);
              handleDelete(id);
            }}
          >
            Deactivate
          </Button>
        )}
        <Button size="lg" variant="dark" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
