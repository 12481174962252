import React, { useEffect, useState } from "react";
import { NFTs } from "../../../../services/demoNFTs";
import rightArrow from "../../../../assets/images/live-traits/right-arrow.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/loader";
import "./liveTraitsNFTDetails.scss";

const LiveTraitsNFTDetails = ({ nftId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [nft, setNft] = useState({});

  const getNft = () => {
    setLoading(true);
    NFTs.map((nft) => {
      if (nft.id === nftId) {
        setNft(nft);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getNft();
  }, [nftId]);

  const getTerminalCode = async () => {
    navigate(`/terminal-code/${nftId}`);
  };

  return (
    <>
      <div className="liveTraitsNFTFlowNFTDetails">
        <div className="liveTraitsNFTFlowNFTDetailsLeftBlock">
          <div style={{ marginBottom: "30px", lineHeight: 1 }}>
            <p className="nameTitle">Name</p>
            <p className="name">{nft?.name}</p>
          </div>
          <div style={{ marginBottom: "30px", lineHeight: 1 }}>
            <p className="descriptionTitle">Description</p>
            <p className="description">{nft?.description}</p>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <p className="propertiesWrapperTitle">Properties</p>
            <div className="properties">
              {nft?.properties?.map((metaData, id) => {
                return (
                  <div key={id}>
                    <div className="propertiesDataWrapper">
                      <p className="propertiesTitle">
                        {Object.keys(metaData)[0]}
                      </p>
                      <p className="propertiesData">
                        {" "}
                        {Object.values(metaData)[0]}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="liveTraitsNFTFlowNFTButtonWrapper">
            <button className="button" onClick={() => getTerminalCode()}>
              <div>
                Create NFT <img src={rightArrow} alt="icon" />
              </div>
            </button>
          </div>
        </div>
        );
        <div className="liveTraitsNFTFlowNFTDetailsRightBlock">
          <p className="liveTraitsNFTFlowNFTDetailsRightBlockHeader">
            Live Preview
          </p>
          <div className="nftDetailsWrapper">
            <div className="nft">
              <div className="nftImageWrapper">
                <img
                  src={nft?.image}
                  alt="NFT"
                  className="nft-image"
                  onLoad={(e) => setLoading(false)}
                  style={{
                    display: loading ? "none" : "block",
                    width: "100%",
                    animation: "fadeIn 0.5s",
                  }}
                />
                <img
                  src={nft?.image}
                  alt="NFT"
                  className="nft-image-background"
                />
              </div>

              {NFTs.map((nft) => {
                if (nft?.id === nftId) {
                  return nft.properties.map((metaData, id) => (
                    <div className="rightBLockNFTDetailsWrapper" key={id}>
                      <div className="rightBLockNFTDetailsTitle">
                        {Object.keys(metaData)[0]}
                      </div>
                      <div className="rightBLockNFTDetailsData">
                        {Object.values(metaData)[0]}{" "}
                      </div>
                    </div>
                  ));
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveTraitsNFTDetails;
