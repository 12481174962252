import React from "react";

const LiveTraitsNFTFlow = ({ nftId, setNFTId }) => {
  const handleOnClick = () => {
    setNFTId();
  };
  return (
    <div className="liveTraitsNFTFlowWrapper">
      <h1 className="liveTraitsNFTFlowHeader">
        Choose a sample NFT to experience Revise live traits
      </h1>
      <div className="liveTraitsNFTFlowDemoNFTList">
        <div className="liveTraitsNFTFlowDemoNFTListChildOne">
          <div
            className={
              nftId === 1
                ? "liveTraitsActiveButton"
                : "liveTraitsNFTFlowDemoNFTName"
            }
            onClick={() => {
              setNFTId(1);
            }}
          >
            <div>Cyborg NFT</div>
          </div>

          <div
            className={
              nftId === 2
                ? "liveTraitsActiveButton"
                : "liveTraitsNFTFlowDemoNFTName"
            }
            onClick={() => {
              setNFTId(2);
            }}
          >
            <div>Weather NFT</div>
          </div>
        </div>
        <div className="liveTraitsNFTFlowDemoNFTListChildTwo">
          <div
            className={
              nftId === 3
                ? "liveTraitsActiveButton"
                : "liveTraitsNFTFlowDemoNFTName"
            }
            onClick={() => {
              setNFTId(3);
            }}
          >
            <div>Sports NFT</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveTraitsNFTFlow;
