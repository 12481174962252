const axios = require("axios");

const BASE_URL = process.env.REACT_APP_API_URL;

function getHeaders({ token }) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

const fetchCollectionsAPI = async ({ token }) => {
  const { data } = await axios.get(
    `${BASE_URL}/collections`,
    getHeaders({ token })
  );
  return data;
};

const fetchNFTsAPI = async ({ token }, { perPage, currentPage }) => {
  const { data } = await axios.get(
    `${BASE_URL}/nfts?perPage=${perPage}&pageNumber=${currentPage}`,
    getHeaders({ token })
  );
  return data;
};

const fetchNFTsAPIV2 = async ({ token }, { perPage, currentPage, keyword }) => {
  const { data } = await axios.get(
    `${BASE_URL}/nfts/v2/paginate?perPage=${perPage}&pageNumber=${currentPage}&keyword=${
      keyword || ""
    }`,
    getHeaders({ token })
  );
  return data;
};

const fetchNFTAPI = async ({ token, nftId }) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/nfts/fetchnft/${nftId}`
      // getHeaders({ token })
    );
    return { ...data, metaData: JSON.parse(data.metaData) };
  } catch (error) {
    // eslint-disable-next-line
    throw {
      response: { data: { code: "INVMD", message: "Invalid meatadata" } },
    };
  }
};

const registerAPI = async ({ email }) => {
  const result = await axios.post(`${BASE_URL}/auth/register`, { email });
  return result;
};

const validateAPI = async ({ token }) => {
  const { data } = await axios.post(`${BASE_URL}/auth/v2/validate`, {
    magicLinkId: token,
  });
  return data;
};

const loginAPI = async ({ email }) => {
  const result = await axios.post(`${BASE_URL}/auth/login`, { email });
  return result;
};

const fetchNFTandRevisionsAPI = async ({ nftId }) => {
  // const {data} = await axios.get(`${BASE_URL}/nfts/fetchrevisions/${nftId}`, getHeaders({token}));
  const {
    data: { nft },
  } = await axios.get(`${BASE_URL}/nfts/v2/fetchnft/${nftId}`);
  try {
    nft.metaData = JSON.parse(nft.metaData);
    nft.revisions = nft.revisions.map((r) => {
      r.metaData = JSON.parse(r.metaData);
      return r;
    });
    return nft;
  } catch (error) {
    // eslint-disable-next-line
    throw {
      response: {
        data: { code: "INVMD", message: "Invalid meatadata", error },
      },
    };
  }
};

const generateAPIKey = async ({ token }) => {
  const { data } = await axios.post(
    `${BASE_URL}/token/create-token`,
    {},
    getHeaders({ token })
  );
  return data;
};

const fetchApiKeys = async ({ token }, { perPage, currentPage }) => {
  const { data } = await axios.get(
    `${BASE_URL}/token/v2/get-all-token?perPage=${perPage}&pageNumber=${currentPage}`,
    getHeaders({ token })
  );
  if (data.tokens) return data;
  return [];
};

const deleteApiKeys = async ({ token, id }) => {
  const { data } = await axios.get(
    `${BASE_URL}/token/v2/blacklist-token/${id}`,
    getHeaders({ token })
  );
  return data;
};

 const fetchNFTDetails = async (config, { token }) => {
   const { data } = await axios.post(
     "https://reviseapi.loca.lt/v3/codeSnippet",
     config,
     getHeaders({ token })
   );
 };

 export {
   fetchNFTDetails,
   fetchCollectionsAPI,
   fetchNFTsAPI,
   fetchNFTAPI,
   registerAPI,
   validateAPI,
   loginAPI,
   fetchNFTandRevisionsAPI,
   generateAPIKey,
   fetchApiKeys,
   fetchNFTsAPIV2,
   deleteApiKeys,
 };

