import React, { useEffect, useState } from "react";
import "./generateapi.scss";
import Table from "../../components/tablegenerateapi";
import Modal from "../../components/genetrateapipopup/modal";
import Header from "../../components/header";
import iIcon from "../../assets/images/generateapi/i.svg";
import { fetchApiKeys, deleteApiKeys } from "../../services/api";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Footer from "../../components/footer";
import { toast } from "react-toastify";

const Generateapi = () => {
  const [popup, setPopup] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [apiKeys, setApiKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    fetchUserProfile().then((userProfile) => {
      fetchKeys(userProfile);
    });
    // eslint-disable-next-line
  }, [currentPage]);

  async function fetchKeys(token) {
    const data = await fetchApiKeys({ token }, { perPage, currentPage });
    let { tokens, pagination } = data;
    setTotalPage(pagination.totalPages);

    setApiKeys(
      tokens.map((k, i) => {
        k.createdAt = moment(k.createdAt).format("Do MMMM YYYY");
        k.sno = perPage * currentPage + (i + 1);
        return k;
      })
    );
  }
  async function fetchUserProfile() {
    const userProfile = await window.localStorage.getItem("userProfile");
    if (userProfile === null || userProfile === undefined) {
      window.location.href = "/signin";
    }
    setUserProfile(userProfile);
    return userProfile;
  }

  const headers = [
    { field: "sno", header: "S NO" },
    { field: "key", header: "SECRET KEY" },
    { field: "createdAt", header: "DATE ADDED" },
    { field: "delete", header: "DELETE" },
  ];

  const handleClick = (e) => {
    e.preventDefault();
    setPopup(true);
  };

  const handleDelete = async (id) => {
    try {
      if (!id) return;
      let latestUserProfile = await fetchUserProfile();
      let result = await deleteApiKeys({ token: latestUserProfile, id: id });
      if (result.status) {
        toast.success("API Key Deactivated..!!");
        if (currentPage !== 0) {
          setCurrentPage(0);
          console.log("1");
        } else {
          console.log("2");
          await fetchKeys(latestUserProfile);
        }
      } else throw { result };
    } catch (error) {
      toast.error("Deactivating the Key Failed, Try Again!!");
    }
  };

  return (
    <>
      <Header />
      <section className="dashboard generateapiSection">
        <div className="generateapi">
          <div className="generateapiKeys">
            <div className="generateapiContent active">
              <div className="generateapiHeading">
                <h2>API Keys</h2>
                <p className="common-btn" onClick={(e) => handleClick(e)}>
                  + Generate API Key
                </p>
              </div>
              <div className="generateapiTable">
                <div className="tableContainer active">
                  <Table
                    headers={headers}
                    tableData={apiKeys}
                    handleDelete={(id) => handleDelete(id)}
                  />
                  <center>
                    <h3 style={{ color: "white", margin: "40px 0" }}>
                      {apiKeys.length === 0 && "No Keys Found"}
                    </h3>
                  </center>
                  <div className="revise-align-center ">
                    <ReactPaginate
                      previousLabel="<<"
                      breakLabel="..."
                      renderOnZeroPageCount={null}
                      nextLabel=">>"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      initialPage={currentPage}
j                      forcePage={currentPage}
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      pageCount={totalPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(e) => {
                        setCurrentPage(parseInt(e.selected));
                      }}
                      containerClassName="pagination"
                      activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="generateapiTip">
            <img alt="" src={iIcon} />
            <h3>Tip</h3>
            <p>
              Revise let's you update your NFT's based on external data and
              triggers
            </p>
            <a href="https://docs.revise.network" className="common-btn">
              LEARN MORE
            </a>
          </div>
        </div>

        <Modal
          show={popup}
          token={userProfile}
          handleClose={() => {
            setPopup(!popup);
            fetchUserProfile().then((userProfile) => {
              fetchKeys(userProfile);
            });
          }}
        />
      </section>
      <hr className="mt-5" />
      <Footer />
    </>
  );
};
export default Generateapi;
