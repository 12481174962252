import React, { useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import revise from "../../assets/images/revise.svg";

const Header = ({ skipLogout }) => {
  const logout = () => {
    window.localStorage.removeItem("userProfile");
    window.localStorage.removeItem("userEmail");
    window.location.href = "/";
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={revise} alt="revise" />
          </Link>
        </div>

        <div>
          {!skipLogout ? (
            <p
              className="common-btn"
              style={{ marginRight: "20px", cursor: "text" }}
            >
              <span className="gradient-border">
                {"Logged In :- " +
                  (window.localStorage.getItem("userEmail") || "Failed")}
              </span>
            </p>
          ) : (
            ""
          )}
          {!skipLogout ? (
            <Link
              to="/generateapi"
              className="common-btn"
              style={{ marginRight: "20px" }}
            >
              <span className="gradient-border">Generate API Key</span>
            </Link>
          ) : (
            ""
          )}

          {!skipLogout ? (
            <p className="common-btn" onClick={() => logout()}>
              <span className="gradient-border">LOGOUT</span>
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </header>
  );
};
export default Header;
