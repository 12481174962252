import React, { useEffect, useState } from "react";
import Delete from "../../assets/images/generateapi/delete.svg";
import DeleteActive from "../../assets/images/generateapi/deleteactive.svg";
import Modal from "./deactivateApiModal";

const TableGenerateApi = ({ headers, tableData, handleDelete }) => {
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    setShow(false);
    setId(null);
  }, [tableData]);

  return (
    <>
      <table>
        <thead>
          <tr>
            {headers.map((head, i) => {
              return (
                <th key={i + "-header-api"} className="title">
                  {head.header}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {tableData.map((row, i) => {
            return (
              <tr key={i}>
                {headers.map((col, j) => {
                  if (j === 3)
                    return (
                      <td className="fields" key={j + "-sub-secret"}>
                        <img
                          src={Delete}
                          className="deleteActiveImg"
                          onClick={() => {
                            setId(row["key"]);
                            setShow(true);
                          }}
                        />
                      </td>
                    );
                  return (
                    <td key={j + "-sub-secret"} className="fields">
                      {row[col.field]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {id && (
        <Modal
          show={show}
          handleClose={() => setShow(false)}
          handleDelete={(id) => handleDelete(id)}
          id={id}
        />
      )}
    </>
  );
};
export default TableGenerateApi;
