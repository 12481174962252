import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../containers/home";
import Revisions from "../containers/revisions";
import Register from "../containers/register";
import Success from "../containers/success";
import Validate from "../containers/success/validate";
import Generateapi from "../containers/generateapi";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LiveTraitsLogin from "../containers/live-traits/login-page/Login";
import LiveTraitCreateNFT from "../containers/live-traits/create-nft/LiveTraitCreateNFT";
import LiveTraitsTerminalPage from "../containers/live-traits/terminal/LiveTraitsTerminalPage";
import Signin from "../containers/signin";

const AppRoutes = () => (
  <Router>
    <ToastContainer limit={5} />
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/revisions/:id" element={<Revisions />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/signin" element={<LiveTraitsLogin />} />
      <Route exact path="/success" element={<Success />} />
      <Route exact path="/validate/token/:token" element={<Validate />} />
      <Route exact path="/generateapi" element={<Generateapi />} />
      <Route exact path="/create-nft" element={<LiveTraitCreateNFT />} />
      <Route
        exact
        path="/terminal-code/:id"
        element={<LiveTraitsTerminalPage />}
      />
    </Routes>
  </Router>
);

export default AppRoutes;
